<template>
	<b-card class="p-1" no-body>
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Nombre</b-th>
						<b-th>Estado</b-th>
						<b-th>Creado en</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>{{ row.name }}</b-td>
						<b-td>
							<b-badge :variant="row.status == 'activo' ? 'success' : 'danger'">{{ row.status }}</b-badge>
						</b-td>
						<b-td>
							<span>{{ row.created.date | myGlobalDayShort }}</span>
							<br />
							<small>{{ row.created.user }}</small>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center" style="gap: 0.5rem">
								<b-button
									size="sm"
									variant="flat-warning"
									@click="
										;(creationModal.info = { ...row, _a: 'edit' }),
											(creationModal.show = !creationModal.show)
									"
								>
									<feather-icon icon="EditIcon" />
								</b-button>
								<b-button size="sm" variant="flat-danger" @click="remove(row)">
									<feather-icon icon="TrashIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="4">
							<p class="mb-0">No hay registros por mostrar</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<CreateMeasureModal
			:show="creationModal.show"
			:info="creationModal.info"
			@closing=";(creationModal.show = false), $emit('actionDone')"
			@refresh="getRows"
		/>
	</b-card>
</template>

<script>
import measureService from "@/services/measure.service"

import CreateMeasureModal from "../components/CreateMeasureModal.vue"

export default {
	components: { CreateMeasureModal },
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: { show: false, info: {} },
	}),
	methods: {
		async getRows() {
			this.isLoading = true
			const { data } = await measureService.getMeasures()
			this.rows = data
			this.isLoading = false
		},
		async remove(row) {
			try {
				const { isConfirmed } = await this.showConfirmSwal({ text: `Se eliminara la raza ${row.name}` })
				if (!isConfirmed) return
				this.isLoading = true
				await measureService.deleteMeasure({ id: row.id })
			} catch (error) {
				this.isLoading = false
			}

			this.getRows()
			this.isLoading = false
		},
	},
	created() {
		this.getRows()
	},
	watch: {
		action(val) {
			if (val == "create-measure") {
				this.creationModal.info = { _a: "create" }
				this.creationModal.show = !this.creationModal.show
			}
		},
	},
}
</script>
